@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap");

:root {
  --black: #000000;
  --black2: #0a0a0a;
  --sky: #229adf;
  --pink: #f5b0cb;
  --lightgray: #dddbd7;
  --lightblue: #c5d9ea;
}

@font-face {
  font-family: "strawford";
  src: url("../public/fonts/Strawford-Regular.otf") format("otf"),
    url("../public/fonts/Strawford-Regular.woff") format("woff"),
    url("../public/fonts/Strawford-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "strawford";
  src: url("../public/fonts/Strawford-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "strawford";
  src: url("../public/fonts/Strawford-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "strawford";
  src: url("../public/fonts/Strawford-Light.ttf") format("truetype");
  font-weight: 300;
}

.raleway {
  font-family: "Raleway", sans-serif;
}

/*------------------------------------*\
    MAIN
\*------------------------------------*/
/* global box-sizing */
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
/* html element 62.5% font-size for REM use */
html {
  font-size: 100%;
  overflow-x: hidden;
}
body {
  font-family: "strawford", Arial, Helvetica, sans-serif;
  font-size: 15px;
  /*color: var(--black2) ;*/
  line-height: 24px;
  font-weight: 400;
}

/* *:not(a)::selection {
  background: var(--lightgreen);
} */

input:focus,
textarea:focus {
  outline: 0;
  border: 0;
}
input[type="checkbox"] {
  accent-color: var(--black2);
}

/* emailinputofcrm */


/* 
body[data-bs-theme=dark] a:not(.custom-btn){
	color: #fff;
}


body:not([data-bs-theme=dark]) a{
	color: #000;
} */

a {
  color: #000;
}

[data-bs-theme="dark"] a {
  color: #ffffff;
}

[data-bs-theme="dark"] .bg-white.custom-btn {
  background: #444444 !important;
}

a:hover {
  color: var(--green2);
}

a,
a:hover {
  text-decoration: none;
}

ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

p:last-child {
  margin: 0;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea,
select {
  width: 100%;
  color: #454545;
  border: 1px solid #d0d0d0;
  padding: 3px 7px;
  font-size: 14px;
  line-height: 15px;
}

.modaldelete {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  min-width: 350px;
  background-color: #fff;
}

.modal-header,
.modal-footer {
  padding: 10px;
}

/*------------------------------------*\
    		WRAPPER CSS
\*------------------------------------*/
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.main {
}

/*------------------------------------*\
    		BUTTON CSS
\*------------------------------------*/
.custom-btn {
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 30px;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  background: var(--sky);
  color: #fff;
  transition: all.3s;
  border: 1px solid var(--sky);
}



.custom-btnlead {
  display: inline-flex;
  padding: 10px 20px;
  border-radius: 30px;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  background: var(--sky);
  color: #fff;
  transition: all.1s;
  border: 1px solid var(--sky);
}

.custom-btnlead:hover{
  color: white;
  cursor: pointer;
}

.border-bl-btn {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}

.custom-btn.sm-btn {
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 20px;
  margin-bottom: 5px;
}

.custom-btnlead.sm-btn {
  line-height: 12px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 20px;
  margin-bottom: 5px;
}

.custom-btn img {
  vertical-align: middle;
}

/*------------------------------------*\
		TYPOGRAPHY FONT CSS
\*------------------------------------*/
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.extrabold {
  font-weight: 800;
}

h1 {
  font-size: 30px;
  line-height: 45px;
}
h2 {
  font-size: 28px;
  line-height: 32px;
}
h3,
.fs-24 {
  font-size: 24px;
  line-height: 28px;
}
h4,
.fs-18 {
  font-size: 18px;
  line-height: 22px;
}
h5,
.fs-16 {
  font-size: 16px;
  line-height: 20px;
}
h6,
.fs-14 {
  font-size: 14px;
  line-height: 19px;
}
.fs-20 {
  font-size: 20px;
  line-height: 26px;
}
.fs-22 {
  font-size: 22px;
  line-height: 28px;
}

.fs-17 {
  font-size: 17px;
  line-height: 23px;
}
.fs-15 {
  font-size: 15px;
  line-height: 22px;
}
.fs-13 {
  font-size: 13px;
  line-height: 17px;
}
.fs-12 {
  font-size: 12px;
  line-height: 18px;
}
.fs-11 {
  font-size: 11px;
  line-height: 15px;
}
.fs-10 {
  font-size: 10px;
  line-height: 14px;
}

.rounded-10 {
  border-radius: 10px;
}

/*------------------------------------*\
		TEXT COLOR CSS
\*------------------------------------*/
.text-black {
  color: #000 !important;
}
.text-sky {
  color: var(--sky);
}
.text-gray {
  color: #999999;
}

.bg-sky {
  background: var(--sky);
}
.bg-pink {
  background: var(--pink);
}
.bg-lightblue {
  background: var(--lightblue);
}
.bg-lightgray {
  background: var(--lightgray);
}
.bg-darkpink {
  background: #9a148d;
}
.bg-brown {
  background: #9d6107;
}
.bg-red {
  background: #ed4b4b !important;
}
.bg-green {
  background: #149a21;
}
.bg-green2 {
  background: #14929a;
}
.bg-red2 {
  background: #9a1414;
}
.bg-blue {
  background: #14319a;
}

.bg-pink-grad {
  background: linear-gradient(180deg, #f5b0cb 0%, rgba(255, 255, 255, 0) 100%);
}
.bg-lightblue-grad {
  background: linear-gradient(180deg, #c5d9ea 0%, rgba(255, 255, 255, 0) 100%);
}
.bg-lightgray-grad {
  background: linear-gradient(180deg, #d3d0cb 0%, rgba(250, 250, 250, 0) 100%);
}
.bg-purple-grad {
  background: linear-gradient(180deg, #b19cd9 0%, rgba(255, 255, 255, 0) 100%);
}

.text-green {
  color: #119627;
}
.text-red {
  color: #ed4b4b;
}
.text-blue {
  color: #2246c4;
}
.text-darkpink {
  color: #9a148d;
}
.text-brown {
  color: #9d6107;
}
.text-yellow {
  color: #e2c00d;
}
.text-green2 {
  color: #14929a;
}
.text-red2 {
  color: #9a1414;
}
.text-blue {
  color: #14319a;
}

/*------------------------------------*\
    		FORM CSS
\*------------------------------------*/

input:focus,
select:focus {
  outline: none;
}

.numberinput::-webkit-outer-spin-button,
.numberinput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.leadbutton{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
}

.plus-button {
  display: inline-flex;
  align-items: center;
  border: 2px solid #007bff; /* Customize the border color as needed */
  border-radius: 5px; /* Adjust the border-radius to your preference */
  padding: 8px 16px; /* Adjust the padding as needed */
  background-color: #007bff; /* Customize the background color as needed */
  color: white; /* Customize the text color as needed */
  font-size: 16px; /* Customize the font size as needed */
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  accent-color: rgba(10, 10, 10, 0.7);
}

textarea {
  resize: none;
}

.cateo:active {
  background-color: bg-blue;
}

/* .cateo:active{
	background-color: bg-blue;
} */

/*------------------------------------*\
    		GLOBAL CSS
\*------------------------------------*/
.br-rad {
  border-radius: var(--br);
}

.bg {
  background-color: var(--bg);
}

/* ------ THEME MODE ----- */

span.theme-mode {
  display: inline-block;
  vertical-align: middle;
}

span.theme-mode label {
  width: 55px;
  height: 26px;
  background: #3f3f3f;
  padding: 3px 5px;
  border-radius: 25px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
}

span.theme-mode label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px #111;
  position: absolute;
  left: 4px;
  transition: transform 0.2s linear;
}

span.theme-mode input:checked ~ label {
  background-color: #000;
}

span.theme-mode input:checked ~ label:before {
  background: #fff;
  transform: translateX(24px);
}

span.theme-mode i.fa-sun {
  padding-right: 4px;
  color: #f1c40f;
}
span.theme-mode .fa-moon {
  color: #f1c40f;
  padding-left: 3px;
}

.translatedrop {
  transform: translate(1068px, 116px) !important
}

/*------------------------------------*\
    		DARK THEME CSS
\*------------------------------------*/
[data-bs-theme="dark"] form.search-form {
  background: var(--bs-secondary-bg);
}

[data-bs-theme="dark"] .border-bl-btn {
  color: #fff;
  border-color: #fff;
}

[data-bs-theme="dark"] .download-menu a > img,
[data-bs-theme="dark"] .user-info > a img,
[data-bs-theme="dark"] .send-mail label img,
[data-bs-theme="dark"] .mail-action a img {
  filter: invert(97%) sepia(100%) saturate(11%) hue-rotate(202deg)
    brightness(105%) contrast(102%);
}

[data-bs-theme="dark"] .filter-menu a.collapse-butn:after,
[data-bs-theme="dark"] .close-modal {
  color: #fff;
}

[data-bs-theme="dark"] .bg-white {
  background: #222e3c !important;
}

[data-bs-theme="dark"] .text-black,
[data-bs-theme="dark"] ul.sub-menu li a {
  color: #fff !important;
}

[data-bs-theme="dark"] ul.sub-menu li::before {
  background-color: #fff !important;
}

[data-bs-theme="dark"]
  .add-form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
[data-bs-theme="dark"] .add-form select,
[data-bs-theme="dark"]
  .site-form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
[data-bs-theme="dark"]
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
[data-bs-theme="dark"] textarea,
[data-bs-theme="dark"] select {
  background: transparent;
  color: #bdc0c5;
}

[data-bs-theme="dark"] .add-form input::placeholder,
[data-bs-theme="dark"]
  input:not([type="checkbox"]):not([type="radio"]):not(
    [type="submit"]
  )::placeholder {
  color: #bdc0c5;
}

[data-bs-theme="dark"] .select2-container--default .select2-selection--single {
  background-color: transparent;
}

[data-bs-theme="dark"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #bdc0c5;
}

[data-bs-theme="dark"] .attach-btn {
  background: #000;
}

[data-bs-theme="dark"] .profile-dropdown,
[data-bs-theme="dark"] .open-collapse {
  background: #222e3c;
}

[data-bs-theme="dark"] .profile-dropdown .collapse-butn {
  background: rgb(103 103 103 / 20%);
  border-bottom: 1px solid #ccc;
  box-shadow: none;
}

[data-bs-theme="dark"]
  .select2-container--default
  .select2-results__option--highlighted[aria-selected],
[data-bs-theme="dark"]
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  color: #000;
}

/*----------- TAB CSS ----------*/

.main-tab .custom-btn:not(.active) {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.sub-tab .custom-btn:not(.active) {
  background: transparent;
  color: #000;
  border: 0;
}

.dash-acco-title[aria-expanded="true"] i {
  transform: rotate(180deg);
}

.dashboard-bg:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-image: url(../src/img/dashboard-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  z-index: -1;
}

.dashboard-bg > div {
  padding-bottom: 150px;
}

/*----------- TABLE CSS ----------*/

.default-table {
  overflow: hidden;
}

.default-table tr th:first-child,
.default-table tr td:first-child {
  padding-left: 20px;
}

.default-table tr th,
.default-table tr td {
  padding: 10px 5px;
  vertical-align: top;
  border-bottom: 1px solid #dcdcdc;
}

.default-table tr:last-child td {
  border-bottom: 0;
}

ul.dropdown-menu {
  min-width: max-content;
}

/*------------------------------------*\
    		ALERT CSS
\*------------------------------------*/

.page-title {
  border-radius: 24px;
  background: linear-gradient(90deg, #229adf 0%, rgba(34, 154, 223, 0) 75.65%);
  color: #fff;
}

.search-form {
  border: 1px solid #1d1d1d;
  padding: 4px 12px;
  border-radius: 18px;
}

.search-form input {
  border: 0 !important;
}

.filter-bar-icon,
.action-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 28px;
  background: #229adf;
  color: #fff;
  border-radius: 4px;
}



.filter-title {
  border-radius: 10px 10px 0px 0px;
}
.filter-menu {
  width: 230px;
}
.download-menu > div {
  padding: 12px 16px;
}
.filter-menu input[type="radio"] {
  width: 14px;
  height: 14px;
  border: 1px solid #0a0a0a;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
  accent-color: var(--sky);
}

.filter-menu a.collapse-butn {
  padding: 12px 16px;
  display: block;
}
.filter-menu a.collapse-butn:after {
  content: "\f107";
  position: absolute;
  right: 16px;
  color: #000;
  z-index: 1;
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
}
.filter-menu a[aria-expanded="true"] {
  background: var(--sky);
  color: #fff;
}
.filter-menu a[aria-expanded="true"]::after {
  rotate: 180deg;
  color: #fff;
}

.result-number {
  width: 28px;
  height: 28px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.deafult-list li {
  list-style: decimal;
  padding-bottom: 5px;
}

.alert-table tr th {
  padding: 18px 20px;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.alert-table tr th:last-child,
.alert-table tr td:last-child {
  border-right: 0;
}

.alert-table tr td {
  vertical-align: top;
  padding: 8px 20px;
  line-height: 18px;
  border-right: 1px solid #dddddd;
}

/*------------------------------------*\
    		LEAD CSS
\*------------------------------------*/

.lead-box {
  min-height: 190px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.lead-box table tr > *:last-child {
  width: 100px;
  padding-right: 10px;
}

/*------------------------------------*\
    		ADD FORM CSS
\*------------------------------------*/
.add-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
.add-form select,
.site-form
  input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 15px;
}

.add-form select {
  -webkit-appearance: none;
  /* ../src/img/icon/drop-down-button.svg */
  background: url(../src/img/icon/drop-down-button.svg) no-repeat right 10px
    center;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  opacity: 0;
}

.select2-container {
  width: 100%;
  display: block;
  z-index: 1;
  font-weight: 500;
  font-size: 12px;
}

.select2-results {
  font-family: Raleway, sans-serif;
  font-size: 12px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  background: url(../src/img/icon/drop-down-button.svg) no-repeat right 10px
    center;
}

.attach-btn {
  font-weight: 500;
  font-size: 12px;
  background: #c7c7c8;
  padding: 7px 12px;
  line-height: 14px;
}

.select2-container--default
  .select2-results
  > .select2-results__options::-webkit-scrollbar {
  display: none;
}

.select2-results__option {
  line-height: 15px;
  font-size: 14px;
  padding: 8px 10px 8px 35px;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-image: url(../src/img/icon/tick.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  background-color: transparent;
  color: initial;
}

span.select2-search.select2-search--dropdown {
  display: none;
}

.profilebuton{
  background: transparent;
  border: none;
}

/*------------------------------------*\
    		ACTION TABLE CSS
\*------------------------------------*/

.modal-footer button {
  min-width: 90px;
  text-align: center;
}

.acco-info-btn[aria-expanded="true"] {
  rotate: 180deg;
}


.action-first {
  width: 120px;
}

.acco-info-table table tr td,
.edit-info-table table tr td {
  vertical-align: top;
}

.edit-info-table input {
  border-radius: 15px;
}

.paginations a {
  display: inline-block;
  color: #767676;
  border-radius: 10px;
  padding: 5px 13px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  transition: all 0.3s ease-in;
  border-radius: 4px;
}
.paginations .page-active,
.paginations a:hover {
  background: var(--sky) !important;
  color: #fff;
}
.paginations a.prev-btn,
.paginations a.next-btn {
  background: #f5f5f5;
}
.custom-number::-webkit-inner-spin-button {
  opacity: 1;
}
.custom-number {
  width: 48px !important;
  background: #f6f6f6;
}
.close-modal {
  border: 1px solid var(--black2);
  color: var(--black2);
  background: transparent;
}
.butn-group button {
  min-width: 88px;
  justify-content: center;
}

.mail-box {
  /* max-width: 560px; */
  /* width: 500px; */
  /* max-height: 90%; */
  position :fixed;
  /* position: absolute; */
  bottom: 0;
  right: 20px;
  /* background-color: rgb(0, 0, 0, 0.5); */
}

.mail-action a {
  display: inline-block;
  line-height: normal;
}

.mail-action a img {
  vertical-align: bottom;
}

.mail-box .mail-close {
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.mail-box input::placeholder {
  color: var(--black2);
}
.mail-box .choose-heading {
  border-top: 1px dashed #e3e1e1;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
}
.mail-box .attachment {
  cursor: pointer;
}

.email-detail::-webkit-scrollbar {
  display: none;
}

.email-detail {
  scrollbar-width: none;
}

/*------------------------------------*\
    		CRM CSS
\*------------------------------------*/

.crm-table tbody tr:first-child td {
  padding-top: 20px;
  line-height: 16px;
}

.manage-table .action-first {
  width: 70px;
}
.action-first span.number {
  width: 48px;
  height: 48px;
  background-color: #fdf4e0;
  color: #f5bd64;
}

.report-list .navitem.active {
  border: 1px solid var(--sky) !important;
}

.ql-container {
  height: 100px;
}

.selectContainer{
	cursor: pointer;
}

.separator {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-weight: bold;
  color: black;
}

.modalpdf {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalpdf-content {
  width: 80%;
  background-color: #fff;
}

[type="date"]::-webkit-inner-spin-button {
  display: block;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
}

.react-datepicker__month-container {
  float: left;
  width: 20rem !important;
  background: linear-gradient(140deg, var(--primary), var(--secondary-lighter), var(--secondary-lighter)) !important;
  box-shadow: 0px 0px 30px #b0b0b0;
  border: none !important;
  border-radius: .5rem;
  padding: 1rem !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: transparent !important;
  border-bottom: 1px solid #dfdfdf;
  border-top-left-radius: 0.3rem;
  font-size: inherit !important;
  height: fit-content;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  height: 1.8rem !important;
}

.react-datepicker__day-names>*,
.react-datepicker__week>* {
  font-size: 1.2rem !important;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  top: 1.2rem !important;
}


.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 0.8rem;
  background: white !important;
  color: #000;
  border: none !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}


/*------------------------------------*\
    		LOGIN CSS
\*------------------------------------*/

/*------------------------------------*\
    	REPORT PRS CSS
\*------------------------------------*/
.prs-table {
  background: #e9ebf4;
  border: 2px solid #fff;
}

.prs-table tr td {
  border: 1px solid #fff;
  padding: 15px 2px;
font-size: 10px;
line-height: 15px;
}

/* .prs-table td:nth-child(6) {
  border: 0px;
  padding: 15px 2px;
  line-height: 15px;
  background: white;
} */

.prs-table tr.prs-head td{
text-align: center;
font-weight: 600;
color: #000000;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

input.no-spinners::-webkit-outer-spin-button,
input.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rbc-calendar {
  background-color: white;
}

.rbc-event {
  background-color: #f0f0f0;
  color: #333; 
}

.rbc-time-header, .rbc-event-content {
  margin-top: 4px;
}

/* .rbc-header + .rbc-header {
  padding-top: 10px;
  padding-bottom: 10px;
} */

.rbc-header + .rbc-header,
.rbc-header:first-child {
  padding: 12px !important;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}

.react-bootstrap-typeahead-input-main {
  outline: none !important;
  /* Add any other custom styling for the input if needed */
}

.date-picker-container {
  /* position: relative;  */
  display: inline-block; 
  width: 200px;
}

.custom-calendar {
  /* position: absolute; */
  /* top: 100%;
  left: 0;
  z-index: 9999; */
  /* max-height: 300px; /* Set a maximum height for the calendar container */
  /* max-width: 300px;  */
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* font-size: 10px; */
}

.custom-calendar .react-datepicker__current-month {
  font-size: 14px; 
}

/* .custom-calendar .react-datepicker__day {
  padding: 0px 0px; 
} */

.custom-calendar .react-datepicker__week {
  margin-bottom: 0px; 
}

.custom-calendar .react-datepicker__day{
  font-size: 0.8rem !important;
  /* color: red;
  background-color: green; */
  /* padding: 0rem !important; */
  padding: 0px 0px; 
}

.custom-calendar .react-datepicker_{
  font-size: 0.6rem !important; 
  /* color: brown !important; 
  background-color: yellow; */
  padding: 0px;
  font-size: 4px;
}

.funnel-chart .d3-funnel svg rect {
  stroke: red;
  stroke-width: 2px;
  /* height: 200px; */
}

/* .rbc-date-cell {
  background-color: white;
} */

/* .parent-div {
  width: 300px; 
  height: 300px; 
  position: relative; 
} */

/* .react-date-picker {
  width: 100%;
}

.react-date-picker__calendar {
  max-height: 90%; 
} */




/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield; 
} */





