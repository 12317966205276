/* .custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: linear-gradient(270deg, #3fa1a9, #79f1a4);
    margin-bottom: 25px;
  } */

  /* .img-wrap{
    position: relative;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
  } */
  /* .img-upload:before{
    content: "\f093";
    font-size: 90px;
    position: absolute;
    padding-top: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #63d3a6;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fff;
  }

  .img-upload:hover:before{
    opacity: 1;
   } */
   /* img {
     width: auto;
     height: 100%;
   } */
   
   label{
     text-transform: uppercase;
     font-weight: 700;
     color: #676767;
   }

   input{
    border-radius: 15px;
    border: 1px solid #b7b7b7;
    padding: 5px 5px 5px 10px;
    font-size: 18px;
    transition: 0.2s;
  }
  input:focus{
    outline: none;
    border: 1px solid #64d488;
  }

  