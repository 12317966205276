/*------------------------------------*\
    		MAIN CSS
\*------------------------------------*/

body:not([data-bs-theme=dark]){
	background: linear-gradient(180deg, #EBF8FF 0%, rgba(255, 255, 255, 0) 100%);
}


.main{	
	min-height: calc(100vh - 137px);
}

.dashboard-box{
	padding: 15px 25px;
	border-radius: 10px;
}

.dashboard-box h2{
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
}

.dashboard-box h2 span{
	border-radius: 20px;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	padding: 5px 12px;
	border-radius: 20px;
	margin-bottom: 10px;
}

.dashboard-box h5 img{
	margin-right: 7px;
	vertical-align: baseline;
}

.value-down{
	background: #FFDACC;
	color: #D46D73;
}

.value-up{
	color: #299B00;
	background: #D4FDC5;
}

.box-1{
	background: linear-gradient(180deg, #FFC0A9 0%, rgba(255, 255, 255, 0) 100%);
}

.box-2{
	background: linear-gradient(180deg, #BFFFA9 0%, rgba(255, 255, 255, 0) 100%);
}

.box-3{
	background: linear-gradient(180deg, #BFA9FF 0%, rgba(255, 255, 255, 0) 100%);
}

.box-4{
	background: linear-gradient(180deg, #FFA9DD 0%, rgba(255, 255, 255, 0) 100%);
}

