/*------------------------------------*\
    		HEADER CSS
\*------------------------------------*/
.top-header{
	background: #1D1D1D;
}

.page-header{
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

}

.checkin-btn{
	background: #57D188;
	border-color: #57D188;
}

.checkout-btn{
	background: red;
	border-color: red;
}

.header .border-end, .header .border-start{
	border-color: #78889B !important;
}

.site-search{
	background: #3A3A3A;
	border-radius: 30px;
	padding: 5px 16px;
}

.site-search form input{
	min-width: 200px;
}

.site-search form input, .site-search form input::placeholder{
	color: #9B9B9B;
}

.nav_area ul li {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	padding: 18px;
}

.nav_area ul li.active, .nav_area ul li:hover{
	background: var(--sky);
}

.nav_area ul li.active a, .nav_area ul li:hover a{
	color: #fff;
}


ul.sub-menu li {	
	position: relative;
	display: block;
}
ul.sub-menu li::before{
	content: '';
	background-color: var(--black);
	width: 5px;
	height: 5px;
	border-radius: 50%;
	position: absolute;
	left: 20px;
	top: 25px;
}

.sub-menu li.active a {
	color: #fff !important;
}
ul.sub-menu li:hover ul.sub-menu li::before{
	background-color: #fff !important;
}
ul.sub-menu li a {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
	line-height: 12px;
    display: inline-block;
    font-weight: 500;
	color: var(--black2) !important;
}

.nav_area ul li:hover span.arrow{
	color: #fff !important;
}


/* ---------------PROFILE-DROPDOWN------------ */
.rounded-4{
	border-radius: 4px !important;
}



.profile-dropdown{
	background: linear-gradient(11.88deg, #B0E2FF 0%, #FFFFFF 100%);
	max-width: 345px;
	width: 345px;
	position: fixed;
    z-index: 3;
    top: 0;
    height: 100%;
    right: -100%;
    transition: all .4s;
}
.profile-dropdown.show{
	right: 0;
}
.profile-dropdown .collapse-butn{
	background: rgba(250, 250, 250, 0.4);
	box-shadow: inset 2.62px -2.62px 2.62px rgba(210, 210, 210, 0.382), inset -2.62px 2.62px 2.62px rgba(255, 255, 255, 0.382);
	backdrop-filter: blur(13.231px);
}
.profile-dropdown a[aria-expanded="true"], .profile-dropdown a.collapse-butn:hover {
    background: var(--sky);
    color: #fff;
}
.profile-dropdown form input{
	border: 1px solid #0A0A0A !important;
	border-radius: 4px;
	background: transparent;
	padding: 7px !important;
}
.open-collapse{
	position: fixed;
    top: -100%;
	right: 0;
	height: 100%;
	width: 345px;
    transition: all .4s;
	z-index: 1;
	background: linear-gradient(11.88deg, #B0E2FF 0%, #FFFFFF 100%);
}
.open-collapse.show{
	top: 0;
}
