/*------------------------------------*\
    RESPONSIVE MIN WIDTH
\*------------------------------------*/

@media (min-width:1150px) {


}


@media (min-width:1025px) {
	
    .action-last{
        width: 25%;
    }
    
}


@media(min-width:992px){

    div#nav_area.collapse {
        display: block !important;
    }

    .modal-sm {
        --bs-modal-width: 360px;
    }

    .modal-460{
        max-width: 460px;
    }

    /* li.menu-item-has-children > ul.sub-menu{
        margin-top: 20px !important;
        margin-left: -19px !important;
    } */

    ul.sub-menu li{
        border-bottom: 1px solid #DADADA;
        padding: 15px 15px 15px 35px;
    }
    ul.sub-menu li:hover{
        background: var(--sky);
    }
    ul.sub-menu li:hover a{
        color: #fff !important;
    }
    span.arrow {
        display: inline-block;
        font-size: 12px;
        line-height: 14px;
        vertical-align: middle;
        padding: 5px 0px 0px 10px;
    }

    .toggle{
        display: none;
    }
	
}



/*------------------------------------*\
    RESPONSIVE MAX WIDTH
\*------------------------------------*/

@media only screen and (max-width:1350px){
   
 
}

@media only screen and (max-width:1250px){

    .prs-table {
        overflow: auto;
        display: block;
    }

}


@media only screen and (max-width:1199px){
    
}

@media only screen and (max-width:1024px){
   
    
    
}



@media only screen and (max-width:991px) {
    

    td.action-mid {
        display: block;        
    }


    td.action-mid + td.action-mid {
        padding-top: 7px;
    }

    td.action-last {
        width: 25%;
    }

    .login-right{
        border-radius: 20px;
    }

    .site-logo {
        border-right: 0 !important;
        padding-bottom: 15px;
    }

    .checkin-btn, .head-time{
        display: none;
    }

    .nav_area ul li{
        display: block;
        padding: 10px 25px;
        background-color: transparent;
    }

    ul.sub-menu li::before{
        top: 15px;
        background-color: #fff !important;
        left: 5px;
    }

    ul.sub-menu li {
        padding: 5px 10px;
        position: relative;
    }

    ul.sub-menu li a{
        color: #fff !important;
    }

    .nav_area ul li span.arrow {
        padding-left: 15px;
    }

    .toggle{
        min-width: 32px;
        height: 32px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 32px;
        background: #000;
        color: #DADADA !important;
        float: right;
    }

    .nav_area {
        position: fixed;
        height: 100vh;
        top: 0;
        background: #fff;
        z-index: 999;
        left: -100%;
        width: 70%;
        max-width: 350px;
        transition: all .4s;   
        overflow: auto;     
    }

    [data-bs-theme=dark]  .nav_area{
        background: #222e3c ;
    }

    ul.sub-menu.dropdown-menu {
        position: relative !important;
        transform: none !important;
        background: transparent;
    }

    .nav_area.show.collapse {
        left: 0;
    }
   
}

@media only screen and (max-width:767px) {
    .action-last{        
        display: block;
    }

    td.action-last {
        width: 100%;
        padding-top: 7px;
    }

    .pwd-success img{
        max-width: 100px;
    }


    
}



@media only screen and (max-width:575px) {
    .profile-dropdown {
        max-width: 300px;
    }

    .profile-dropdown .rounded-circle {
        width: 70px;
        height: 70px;
    }

    .custom-btn.sm-btn {
        padding: 6px 10px;
    }

    .alert-table tr th {
        padding: 10px 5px;
    }

    .alert-table tr td{
        padding: 8px 5px;
    }
   
}

@media only screen and (max-width:480px) {

}